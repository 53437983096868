@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.transport {
	&__headline {
		color: $black;
		margin-bottom: 16px;
	}

	&__description {
		margin-bottom: 14px;
	}
}
