@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$green-trip-advisor: #00af87 !default;

.tripadvisor {
	display: flex;
	align-items: center;

	&__rating {
		display: flex;

		img {
			height: 15px;
			margin-left: -7px; // le logo trip advisor a des espaces sur les cotés

			@include gridle_state(md) {
				height: 17px;
			}
		}
	}

	&__reviews {
		color: $green-trip-advisor;
	}
}
