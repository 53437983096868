@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.marketing-banner {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $grey-dark;
	color: white;
	padding: 10px;
	font-size: 1.6rem;
	text-align: center;

	.paragraphs {
		&__item {
			margin-bottom: 0;
		}
	}

	a {
		margin-left: 5px;
		color: inherit;
		font-size: 1.6rem;
	}

	&__promocode {
		margin-right: 20px;
		border: 1px solid currentColor;
		padding: 1px 10px;
		border-radius: $border-radius-small;
	}
}
