@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.travel-documents {
	&__headline {
		color: $black;
		margin-bottom: 16px;
	}

	&__description {
		@extend %text;
		margin-bottom: 14px;
	}

	.separator {
		margin: 30px auto;
		color: $black;
	}
}
