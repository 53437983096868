@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../.styles/collapse";

.more-details {
	.rc-collapse > .rc-collapse-item > .rc-collapse-header,
	.rc-collapse-header {
		@extend %link;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		outline: none; // pas de bordure bleu du navigateur

		&--suffix {
			color: $grey-dark;
		}
	}

	.rc-collapse-content > .rc-collapse-content-box {
		padding: 10px 13px;
	}
}
