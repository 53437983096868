@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.smartDPPpatchwork {
	&__item {
		float: left;
		cursor: pointer;

		&:nth-child(1) {
			width: 372px;
			height: 202px;
			margin-right: 3px;

			img {
				border-radius: $border-radius-big 0 0 $border-radius-big;
			}
		}

		&:nth-child(2) {
			width: 185px;
			margin-bottom: 3px;
			height: 99px;

			img {
				border-radius: 0 $border-radius-big 0 0;
			}
		}

		&:nth-child(3) {
			width: 185px;
			position: relative;
			height: 99px;

			img {
				filter: brightness(50%);
				border-radius: 0 0 $border-radius-big 0;
			}
		}

		img {
			filter: brightness(
				100%
			); // hack pour pouvoir cliquer sur l'image à cause de .options qui empêche de le faire
		}
	}

	&__more {
		@extend %font-bold;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		font-size: 1.6rem;
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: white;
	}
}
