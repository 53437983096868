@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../FicheProduitCommon";

.additional-sections {
	&__title {
		@extend %fp-bloc-header;
	}

	&__item {
		padding-top: 20px;

		&:first-child {
			padding-top: 0;
		}

		@include gridle_state(md) {
			padding-top: 30px;
		}
	}

	&__details {
		margin-top: 20px;
	}
}
