@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.formalities {
	&__headline {
		color: $black;
		margin-bottom: 16px;
	}

	&-list {
		margin-top: 20px;

		&__item {
			margin-bottom: 22px;
		}

		&__headline {
			@extend %font-bold;
			font-size: 1.4rem;
			color: $black;
			margin-bottom: 10px;
		}
	}
}
