@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sdp-breadcrumb {
	.breadcrumbs {
		&-item {
			&__label {
				white-space: pre;
				letter-spacing: normal;
			}

			&__position {
				width: 17px;
				height: 17px;

				svg {
					width: 8px;
					height: 8px;
				}
			}

			&__spacer {
				width: 200px;
				margin-left: 0;
				margin-right: 0;
				border-bottom-color: $grey-medium;
			}

			&--passed {
				.breadcrumbs-item__position {
					background: white;
					color: $active-color;
				}
			}

			&--active {
				.breadcrumbs-item__position {
					background: $active-color;
					color: $active-color;
					border-width: 2px;
				}
			}

			&--disabled {
				.breadcrumbs-item__position {
					background: $grey-medium;
					color: $grey-medium;
					border-color: $grey-medium;
				}

				.breadcrumbs-item__label {
					color: $grey-medium;
				}
			}
		}
	}
}
