@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.useful-information {
	padding-top: 0;
	padding-bottom: 30px;

	@include gridle_state(md) {
		padding-top: 30px;
		padding-bottom: 60px;
		display: grid;
		display: -ms-grid;
		-ms-grid-columns: 490px 44px 490px;
		grid-template-columns: 490px 490px;
		grid-column-gap: 44px;
	}

	&__headline {
		display: none;

		@include gridle_state(md) {
			grid-column: 1 / span 2;
			color: $black;
			display: block;
			padding-bottom: 20px;
			text-align: center;
			border-bottom: 1px solid $grey-medium;
		}
	}

	&__item {
		padding: 20px 0;

		@include gridle_state(md) {
			grid-column: 1;

			&:nth-child(2n) {
				grid-column: 2;
			}

			&:nth-child(2n + 1) {
				grid-column: 1;
			}

			&:first-of-type {
				grid-column: 1 / span 2;
				padding: 20px 0;
			}
		}
	}
}
