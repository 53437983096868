@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.good-to-know {
	&__headline {
		color: $black;
		margin-bottom: 16px;
	}

	&__description {
		@extend %text;
		margin-bottom: 14px;
	}

	&__perfectstay-logo {
		width: 200px;
		margin-left: 10px;
	}
}
