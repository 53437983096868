$fp-mobile-padding: 10px !default;

%fp-bloc-header {
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid $grey-medium;
}

%fp-text {
	@extend %font-regular;
	font-size: 1.4rem;
	line-height: 1.6;
	color: $black;
	letter-spacing: normal;
}

%fp-text-light {
	@extend %font-regular;
	font-size: 1.4rem;
	line-height: 1.6;
	color: $grey-dark;
	letter-spacing: normal;
}

%down-arrow-caret {
	&:not(:first-of-type) {
		padding-top: 30px;
	}

	&:last-of-type {
		border-radius: 0 0 $border-radius-big $border-radius-big;
	}

	&:not(:last-of-type) {
		&:after,
		&:before {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: "";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:after {
			border-color: rgba(255, 255, 255, 0);
			border-top-color: white;
			border-width: 15px;
			margin-left: -19px;
		}

		&:before {
			border-color: rgba(0, 0, 0, 0);
			border-top-color: $grey-medium;
			border-width: 16px;
			margin-left: -20px;
		}
	}
}
